const dateTypeShoes = [
  {
    id: 1,
    img: "/images/1.webp",
    alt: "lates-Shoes",
    title: "lates Shoes",
    price: 90000,
  },
  {
    id: 2,
    img: "/images/2.webp",
    alt: "sport Shoes",
    title: "sport Shoes",
    price: 95000,
  },
  {
    id: 3,
    img: "/images/3.webp",
    alt: "office-Shoes",
    title: "office Shoes",
    price: 82000,
  },
];
export default dateTypeShoes;
