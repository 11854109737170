const dataSlide = [
  {
    id: 1,
    img: "/images/slider-01.webp",
    title: "Exclusive New Shoes",
    text: "Up To 30% Off All Shoes and Products",
    link: "Shop now",
    alt: "shoes-01",
  },
  {
    id: 2,
    img: "/images/slider-02.png",
    title: "Exclusive New Shoes",
    text: "Up To 30% Off All Shoes and Products",
    link: "Shop now",
    alt: "shoes-02",
  },
  {
    id: 3,
    img: "/images/slider-03.webp",
    title: "Exclusive New Shoes",
    text: "Up To 30% Off All Shoes and Products",
    link: "Shop now",
    alt: "shoes-03",
  },
];
export default dataSlide;
