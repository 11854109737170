import React from "react";
import "./footer.css";
const Footer = () => {
  return (
    <>
      <div className="container-fluid footer">
        <img
          src="https://htmldemo.net/shome/shome/assets/img/logo-light.webp"
          alt=""
        />
        <p>
          Lorem ipsum dolor sit amet consl adipisi elit, sed do eiusmod templ
          incididunt ut labore
        </p>
      </div>
    </>
  );
};

export default Footer;
