export const products = [
  {
    id: "1",
    img: "/images/s-1.webp",
    alt: "Hollister-V-Neck Knit",
    text: "rexpo-Hollister V-Neck Knit",
    price: 88000,
    brand: "rexpo",
    count: 1,
    type: "x",
    category: "small",
    thubm: {
      thubm1: "/images/sm-1.webp",
      thubm2: "/images/sm-2.webp",
      thumb3: "/images/sm-3.webp",
      thubm4: "/images/sm-4.webp",
    },
  },

  {
    id: "2",
    img: "/images/s-2.webp",
    alt: "Rexpo-Womens-shoes",
    text: "quickiin Womens shoes",
    price: 600000,
    brand: "quickiin",
    count: 1,
    type: "x",
    category: "small",
    thubm: {
      thubm1: "/images/s-5.webp",
      thubm2: "/images/sm-1.webp",
      thumb3: "/images/sm-4.webp",
      thubm4: "/images/sm-6.webp",
    },
  },
  {
    id: "3",
    img: "/images/s-3.webp",
    alt: "Quickiin-Mens-shoes",
    text: "nike Mens shoes",
    price: 14000,
    brand: "nike",
    count: 1,
    type: "x",
    category: "small",
    thubm: {
      thubm1: "/images/sm-4.webp",
      thubm2: "/images/sm-1.webp",
      thumb3: "/images/sm-7.webp",
      thubm4: "/images/sm-2.webp",
    },
  },
  {
    id: "4",
    img: "/images/s-4.webp",
    alt: "Leather Mens Slipper",
    text: "rexpo-Leather Mens Slipper",
    price: 100000,
    brand: "rexpo",
    count: 1,
    type: "x",
    category: "small",
    thubm: {
      thubm1: "/images/sm-6.webp",
      thubm2: "/images/sm-1.webp",
      thumb3: "/images/sm-7.webp",
      thubm4: "/images/sm-2.webp",
    },
  },
  {
    id: "5",
    img: "/images/s-7.webp",
    alt: "exclusive mens shoe",
    text: "rexpo-exclusive mens shoe",
    price: 30000,
    brand: "rexpo",
    count: 1,
    type: "x",
    category: "large",
    thubm: {
      thubm1: "/images/sm-8.webp",
      thubm2: "/images/sm-1.webp",
      thumb3: "/images/sm-4.webp",
      thubm4: "/images/sm-6.webp",
    },
  },
  {
    id: "6",
    img: "/images/s-2.webp",
    alt: "Simple Fabric Shoe",
    text: "quickiin-Simple Fabric Shoe",
    price: 13300,
    brand: "quickiin",
    count: 1,
    type: "x",
    category: "x-large",
    thubm: {
      thubm1: "/images/sm-5.webp",
      thubm2: "/images/sm-1.webp",
      thumb3: "/images/sm-4.webp",
      thubm4: "/images/sm-6.webp",
    },
  },
  {
    id: "7",
    img: "/images/s-5.webp",
    alt: "New Womens High Hills",
    text: "quickiin-New Womens High Hills",
    price: 3000,
    brand: "quickiin",
    count: 1,
    type: "x",
    category: "x-large",
    thubm: {
      thubm1: "/images/sm-2.webp",
      thubm2: "/images/sm-7.webp",
      thumb3: "/images/sm-3.webp",
      thubm4: "/images/sm-4.webp",
    },
  },
  {
    id: "8",
    img: "/images/s-6.webp",
    alt: "Primitive Mens shoes",
    text: "nike-Primitive Mens shoes",
    price: 50000,
    brand: "nike",
    count: 1,
    type: "x",
    category: "xx-large",
    thubm: {
      thubm1: "/images/sm-3.webp",
      thubm2: "/images/sm-2.webp",
      thumb3: "/images/sm-7.webp",
      thubm4: "/images/sm-6.webp",
    },
  },
  {
    id: "9",
    img: "/images/s-2.webp",
    alt: "Primitive Mens shoes",
    text: "nike-Primitive Mens shoes",
    price: 50000,
    brand: "nike",
    count: 1,
    type: "x",
    category: "xx-large",
    thubm: {
      thubm1: "/images/sm-5.webp",
      thubm2: "/images/sm-1.webp",
      thumb3: "/images/sm-4.webp",
      thubm4: "/images/sm-6.webp",
    },
  },
];
