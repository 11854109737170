import React from "react";

const AbouteHeader = () => {
  return (
    <>
      <div className="headerpage">
        <img
          src="/images/h-0.webp"
          alt="productpage"
        />
        <h3 className="headerpagetitle">about us</h3>
      </div>
    </>
  );
};

export default AbouteHeader;
