import React from "react";
import "./error.css";
function Error() {
  return (
    <>
      <div className="errorpage">
        <p>404</p>
      </div>
    </>
  );
}

export default Error;
